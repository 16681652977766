const role = {}

const permissionNoPager = [
  {
    'id': 'administration',
    'site': 'corporation',
    'name': '授权行政车辆',
    'description': 'in_out',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 73},{"action":"add","defaultCheck":false,"description":"新增","pid": 71},{"action":"delete","defaultCheck":false,"description":"删除","pid": 72}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'delete'
    ]
  },
  {
    'id': 'administration',
    'site': 'admin',
    'name': '授权行政车辆',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 73},{"action":"add","defaultCheck":false,"description":"新增","pid": 71},{"action":"delete","defaultCheck":false,"description":"删除","pid": 72}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'delete'
    ]
  },
  {
    'id': 'inout_application',
    'site': 'corporation',
    'name': '进出区申请单',
    'description': 'in_out',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 65},{"action":"add","defaultCheck":false,"description":"新增","pid": 62},{"action":"edit","defaultCheck":false,"description":"修改","pid": 64},{"action":"delete","defaultCheck":false,"description":"删除","pid": 63}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'inout_application',
    'site': 'admin',
    'name': '进出区申请单',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 65},{"action":"add","defaultCheck":false,"description":"新增","pid": 62},{"action":"edit","defaultCheck":false,"description":"修改","pid": 64},{"action":"delete","defaultCheck":false,"description":"删除","pid": 63}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'inout_book',
    'name': '进出区台账目',
    'site': 'corporation',
    'description': 'in_out',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 67},{"action":"add","defaultCheck":false,"description":"新增","pid": 66}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query'
    ]
  },
  {
    'id': 'inout_book',
    'name': '进出区台账目',
    'site': 'admin',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 67},{"action":"add","defaultCheck":false,"description":"新增","pid": 66}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query'
    ]
  },
  {
    'id': 'zone_property',
    'name': '资产',
    'site': 'admin',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 191},{"action":"add","defaultCheck":false,"description":"创建","pid": 188},{"action":"edit","defaultCheck":false,"description":"编辑","pid": 190},{"action":"delete","defaultCheck":false,"description":"删除","pid": 189}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'add',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'property_project',
    'name': '资产项目',
    'site': 'admin',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 250},{"action":"add","defaultCheck":false,"description":"创建","pid": 251},{"action":"edit","defaultCheck":false,"description":"编辑","pid": 252},{"action":"delete","defaultCheck":false,"description":"删除","pid": 253}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'add',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'property_project_item',
    'name': '资产项目条目',
    'site': 'admin',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 254},{"action":"add","defaultCheck":false,"description":"创建","pid": 255},{"action":"edit","defaultCheck":false,"description":"编辑","pid": 256},{"action":"delete","defaultCheck":false,"description":"删除","pid": 257},{"action":"import","defaultCheck":false,"description":"创建付款单","pid": 258}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'add',
      'edit',
      'delete',
      'import'
    ]
  },
  {
    'id': 'contract_information',
    'site': 'corporation',
    'name': '合同信息',
    'description': 'contract',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 56}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'contract_information',
    'site': 'admin',
    'name': '合同信息',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 56},{"action":"add","defaultCheck":false,"description":"新增","pid": 53},{"action":"edit","defaultCheck":false,"description":"修改","pid": 55},{"action":"delete","defaultCheck":false,"description":"删除","pid": 54},{"action":"force_edit","defaultCheck":false,"description":"强制修改合同状态","pid": 176}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete',
      'force_edit'
    ]
  },
  {
    'id': 'contract_template',
    'site': 'corporation',
    'name': '合同模板',
    'description': 'contract',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 52},{"action":"add","defaultCheck":false,"description":"新增","pid": 50},{"action":"delete","defaultCheck":false,"description":"删除","pid": 51}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'delete'
    ]
  },
  {
    'id': 'contract_template',
    'site': 'admin',
    'name': '合同模板',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 52},{"action":"add","defaultCheck":false,"description":"新增","pid": 50},{"action":"delete","defaultCheck":false,"description":"删除","pid": 51}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'delete'
    ]
  },
  {
    'id': 'electricity_fees',
    'site': 'corporation',
    'name': '电费账单',
    'description': 'bill',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 86},{"action":"add","defaultCheck":false,"description":"新增","pid": 83},{"action":"edit","defaultCheck":false,"description":"修改","pid": 85},{"action":"delete","defaultCheck":false,"description":"删除","pid": 84}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'electricity_fees',
    'site': 'admin',
    'name': '电费账单',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 86},{"action":"add","defaultCheck":false,"description":"新增","pid": 83},{"action":"edit","defaultCheck":false,"description":"修改","pid": 85},{"action":"delete","defaultCheck":false,"description":"删除","pid": 84}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'water_fees',
    'site': 'corporation',
    'name': '水费账单',
    'description': 'bill',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 82},{"action":"add","defaultCheck":false,"description":"新增","pid": 80},{"action":"edit","defaultCheck":false,"description":"修改","pid": 81}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit'
    ]
  },
  {
    'id': 'water_fees',
    'site': 'admin',
    'name': '水费账单',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 82},{"action":"add","defaultCheck":false,"description":"新增","pid": 80},{"action":"edit","defaultCheck":false,"description":"修改","pid": 81}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit'
    ]
  },
  {
    'id': 'rent_fees',
    'site': 'corporation',
    'name': '租金账单',
    'description': 'bill',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 61},{"action":"add","defaultCheck":false,"description":"新增","pid": 58},{"action":"edit","defaultCheck":false,"description":"修改","pid": 60},{"action":"delete","defaultCheck":false,"description":"删除","pid": 59}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'rent_fees',
    'site': 'admin',
    'name': '租金账单',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 61},{"action":"add","defaultCheck":false,"description":"新增","pid": 58},{"action":"edit","defaultCheck":false,"description":"修改","pid": 60},{"action":"delete","defaultCheck":false,"description":"删除","pid": 59}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'public_area_fees',
    'site': 'admin',
    'name': '公共区域费用',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 177},{"action":"add","defaultCheck":false,"description":"新增","pid": 178},{"action":"delete","defaultCheck":false,"description":"删除","pid": 179},{"action":"edit","defaultCheck":false,"description":"修改","pid": 180}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'guarantee_fees',
    'site': 'corporation',
    'name': '保证金账单',
    'description': 'bill',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 181},{"action":"delete","defaultCheck":false,"description":"删除","pid": 182}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'query',
      'delete'
    ]
  },
  {
    'id': 'guarantee_fees',
    'site': 'admin',
    'name': '保证金账单',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 181},{"action":"delete","defaultCheck":false,"description":"删除","pid": 182}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'query',
      'delete'
    ]
  },
  {
    'id': 'urban_land_use_tax',
    'site': 'admin',
    'name': '城镇土地使用税',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 183},{"action":"add","defaultCheck":false,"description":"新增","pid": 184},{"action":"delete","defaultCheck":false,"description":"删除","pid": 185},{"action":"edit","defaultCheck":false,"description":"修改","pid": 186}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'property_fees',
    'site': 'corporation',
    'name': '物业账单',
    'description': 'bill',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 61},{"action":"add","defaultCheck":false,"description":"新增","pid": 58},{"action":"edit","defaultCheck":false,"description":"修改","pid": 60},{"action":"delete","defaultCheck":false,"description":"删除","pid": 59}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'property_fees',
    'site': 'admin',
    'name': '物业账单',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 61},{"action":"add","defaultCheck":false,"description":"新增","pid": 58},{"action":"edit","defaultCheck":false,"description":"修改","pid": 60},{"action":"delete","defaultCheck":false,"description":"删除","pid": 59}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  // {
  //   'id': 'payment_ticket',
  //   'site': 'corporation',
  //   'name': '付款凭证',
  //   'description': null,
  //   'status': 1,
  //   'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 90},{"action":"add","defaultCheck":false,"description":"新增","pid": 87},{"action":"edit","defaultCheck":false,"description":"修改","pid": 89},{"action":"delete","defaultCheck":false,"description":"删除","pid": 88}]',
  //   'sptDaTypes': null,
  //   'optionalFields': null,
  //   'parents': null,
  //   'type': null,
  //   'deleted': 0,
  //   'actions': [
  //     'add',
  //     'query',
  //     'edit',
  //     'delete'
  //   ]
  // },
  // {
  //   'id': 'payment_ticket',
  //   'site': 'admin',
  //   'name': '付款凭证',
  //   'description': null,
  //   'status': 1,
  //   'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 90},{"action":"add","defaultCheck":false,"description":"新增","pid": 87},{"action":"edit","defaultCheck":false,"description":"修改","pid": 89},{"action":"delete","defaultCheck":false,"description":"删除","pid": 88}]',
  //   'sptDaTypes': null,
  //   'optionalFields': null,
  //   'parents': null,
  //   'type': null,
  //   'deleted': 0,
  //   'actions': [
  //     'add',
  //     'query',
  //     'edit',
  //     'delete'
  //   ]
  // },
  // {
  //   'id': 'fapiao_application',
  //   'site': 'corporation',
  //   'name': '发票申请',
  //   'description': null,
  //   'status': 1,
  //   'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 93},{"action":"add","defaultCheck":false,"description":"新增","pid": 91},{"action":"edit","defaultCheck":false,"description":"修改","pid": 92}]',
  //   'sptDaTypes': null,
  //   'optionalFields': null,
  //   'parents': null,
  //   'type': null,
  //   'deleted': 0,
  //   'actions': [
  //     'add',
  //     'query',
  //     'edit'
  //   ]
  // },
  {
    'id': 'property_bank_flow',
    'site': 'admin',
    'name': '银企直连对账单',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 242},{"action":"delete","defaultCheck":false,"description":"删除","pid": 243}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'query',
      'delete'
    ]
  },
  {
    'id': 'fund_flow',
    'site': 'admin',
    'name': '资金流水',
    'description': 'finance',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 244},{"action":"add","defaultCheck":false,"description":"新增","pid": 245}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'query',
      'add'
    ]
  },
  {
    'id': 'bill_check',
    'site': 'admin',
    'name': '账单审核',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"审核","pid": 246}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'property_balance',
    'site': 'admin',
    'name': '企业资产余额',
    'description': 'finance',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 247}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  // {
  //   'id': 'fapiao_application',
  //   'site': 'admin',
  //   'name': '发票申请',
  //   'description': null,
  //   'status': 1,
  //   'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 93},{"action":"add","defaultCheck":false,"description":"新增","pid": 91},{"action":"edit","defaultCheck":false,"description":"修改","pid": 92}]',
  //   'sptDaTypes': null,
  //   'optionalFields': null,
  //   'parents': null,
  //   'type': null,
  //   'deleted': 0,
  //   'actions': [
  //     'add',
  //     'query',
  //     'edit'
  //   ]
  // },
  // {
  //   'id': 'bank_payment_account',
  //   'name': '打款账户',
  //   'site': 'admin',
  //   'description': null,
  //   'status': 1,
  //   'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 172},{"action":"add","defaultCheck":false,"description":"添加","pid": 173},{"action":"edit","defaultCheck":false,"description":"编辑","pid": 174}]',
  //   'sptDaTypes': null,
  //   'optionalFields': '[]',
  //   'parents': null,
  //   'type': 'default',
  //   'deleted': 0,
  //   'actions': [
  //     'query',
  //     'edit',
  //     'add'
  //   ]
  // },
  // {
  //   'id': 'payment_bank_water',
  //   'name': '银行流水',
  //   'site': 'admin',
  //   'description': null,
  //   'status': 1,
  //   'actionData': '[{"action":"edit","defaultCheck":false,"description":"银行流水审核","pid": 171}]',
  //   'sptDaTypes': null,
  //   'optionalFields': '[]',
  //   'parents': null,
  //   'type': 'default',
  //   'deleted': 0,
  //   'actions': [
  //     'edit'
  //   ]
  // },
  {
    'id': 'dingding',
    'name': '钉钉',
    'site': 'admin',
    'description': 'user',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"钉钉配置","pid": 231}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'bank_account',
    'name': '银行账户',
    'site': 'corporation',
    'description': 'finance',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"获取企业资产银行账户","pid": 232}, {"action":"add","defaultCheck":false,"description":"新增企业资产银行账户","pid": 233}, {"action":"delete","defaultCheck":false,"description":"删除企业资产银行账户","pid": 234}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'add',
      'delete'
    ]
  },
  {
    'id': 'electricity_meter',
    'name': '电表',
    'site': 'admin',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"获取电表","pid": 235}, {"action":"add","defaultCheck":false,"description":"新增电表","pid": 236}, {"action":"edit","defaultCheck":false,"description":"修改电表","pid": 237},{"action":"delete","defaultCheck":false,"description":"删除电表","pid": 238},{"action":"get","defaultCheck":false,"description":"获取电表记录","pid": 239},{"action":"commit","defaultCheck":false,"description":"新增电表记录","pid": 240},{"action":"disable","defaultCheck":false,"description":"删除电表记录","pid": 241}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'add',
      'edit',
      'delete',
      'get',
      'commit',
      'disable'
    ]
  },
  {
    'id': 'electricity_meter_balance',
    'site': 'admin',
    'name': '电表余额',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询","pid": 248},{"action":"add","defaultCheck":true,"description":"新增","pid": 249}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'query',
      'add'
    ]
  },
  {
    'id': 'electricity_meter',
    'name': '电表',
    'site': 'corporation',
    'description': 'bill',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"获取电表","pid": 235}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'electricity_meter_balance',
    'site': 'corporation',
    'name': '电表余额',
    'description': 'bill',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":true,"description":"查询","pid": 248},{"action":"add","defaultCheck":true,"description":"新增","pid": 249}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'query',
      'add'
    ]
  },
  {
    'id': 'electricity_meter_record',
    'name': '电表记录',
    'site': 'corporation',
    'description': 'bill',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询记录","pid": 239}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'water_meter_record',
    'name': '抄表',
    'site': 'admin',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 79},{"action":"add","defaultCheck":false,"description":"新增","pid": 77},{"action":"delete","defaultCheck":false,"description":"删除","pid": 78}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'water_meter_record',
    'name': '水表账单记录',
    'site': 'corporation',
    'description': 'bill',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 79}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'water_meter',
    'name': '水表',
    'site': 'admin',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 76},{"action":"add","defaultCheck":false,"description":"新增","pid": 74},{"action":"delete","defaultCheck":false,"description":"删除","pid": 75}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'undertaking',
    'site': 'corporation',
    'name': '承诺书',
    'description': 'basic',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 70},{"action":"add","defaultCheck":false,"description":"新增","pid": 68},{"action":"delete","defaultCheck":false,"description":"删除","pid": 69}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'delete'
    ]
  },
  {
    'id': 'undertaking',
    'site': 'admin',
    'name': '承诺书',
    'description': 'corporation',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 70},{"action":"add","defaultCheck":false,"description":"新增","pid": 68},{"action":"delete","defaultCheck":false,"description":"删除","pid": 69}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'delete'
    ]
  },
  {
    'id': 'fapiao_information',
    'site': 'corporation',
    'name': '发票信息',
    'description': 'finance',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询"},{"action":"get","defaultCheck":false,"description":"详情"},{"action":"add","defaultCheck":false,"description":"新增"},{"action":"edit","defaultCheck":false,"description":"修改"},{"action":"delete","defaultCheck":false,"description":"删除"}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'get',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'notification',
    'name': '通知',
    'site': 'admin',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 101},{"action":"add","defaultCheck":false,"description":"新增","pid": 99},{"action":"delete","defaultCheck":false,"description":"删除","pid": 100}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 1,
    'actions': [
      'add',
      'query',
      'delete'
    ]
  },
  {
    'id': 'user_information',
    'site': 'corporation',
    'name': '用户信息',
    'description': 'user',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 4},{"action":"add","defaultCheck":false,"description":"新增","pid": 1},{"action":"edit","defaultCheck":false,"description":"修改","pid": 3},{"action":"delete","defaultCheck":false,"description":"删除","pid": 2}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'user_information',
    'site': 'admin',
    'name': '用户信息',
    'description': 'user',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 4},{"action":"add","defaultCheck":false,"description":"新增","pid": 1},{"action":"edit","defaultCheck":false,"description":"修改","pid": 3},{"action":"delete","defaultCheck":false,"description":"删除","pid": 2}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'user_settings',
    'site': 'corporation',
    'name': '用户设置',
    'description': 'user',
    'status': 1,
    'actionData': '[{"action":"edit","defaultCheck":false,"description":"重置密码","pid": 7},{"action":"update","defaultCheck":false,"description":"关联用户与企业","pid": 5},{"action":"delete","defaultCheck":false,"description":"删除用户与企业关联","pid": 6}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'edit',
      'update',
      'delete'
    ]
  },
  {
    'id': 'user_settings',
    'site': 'admin',
    'name': '用户设置',
    'description': 'user',
    'status': 1,
    'actionData': '[{"action":"update","defaultCheck":false,"description":"关联用户与企业","pid": 5},{"action":"delete","defaultCheck":false,"description":"删除用户与企业关联","pid": 6}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'update',
      'delete'
    ]
  },
  {
    'id': 'temporary',
    'site': 'corporation',
    'name': '入区登记',
    'description': 'in_out',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查看"}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'temporary',
    'site': 'admin',
    'name': '入区登记',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查看"}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'tag',
    'name': '企业标签',
    'site': 'admin',
    'description': 'corporation',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 23},{"action":"add","defaultCheck":false,"description":"新增","pid": 20},{"action":"delete","defaultCheck":false,"description":"删除","pid": 21},{"action":"update","defaultCheck":false,"description":"更新","pid": 22}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'delete',
      'update'
    ]
  },
  {
    'id': 'corporation',
    'name': '企业',
    'site': 'admin',
    'description': 'corporation',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 19},{"action":"add","defaultCheck":false,"description":"新增","pid": 16},{"action":"edit","defaultCheck":false,"description":"修改","pid": 18},{"action":"delete","defaultCheck":false,"description":"删除","pid": 17}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'role',
    'site': 'admin',
    'name': '权限组配置',
    'description': 'user',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 11},{"action":"add","defaultCheck":false,"description":"新增","pid": 8},{"action":"edit","defaultCheck":false,"description":"修改","pid": 10},{"action":"delete","defaultCheck":false,"description":"删除","pid": 9}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'role',
    'site': 'corporation',
    'name': '权限组配置',
    'description': 'user',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 11},{"action":"add","defaultCheck":false,"description":"新增","pid": 8},{"action":"edit","defaultCheck":false,"description":"修改","pid": 10},{"action":"delete","defaultCheck":false,"description":"删除","pid": 9}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'user_peizhi',
    'name': '用户权限配置',
    'site': 'admin',
    'description': 'user',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"获取用户与权限组","pid": 14},{"action":"get","defaultCheck":false,"description":"获取权限与权限组","pid": 12},{"action":"edit","defaultCheck":false,"description":"更新用户与权限组","pid": 15},{"action":"delete","defaultCheck":false,"description":"更新权限与权限组","pid": 13},{"action":"import","defaultCheck":false,"description":"添加企业与企业标签关联","pid": 24},{"action":"export","defaultCheck":false,"description":"删除企业与企业标签关联","pid": 25}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'get',
      'query',
      'edit',
      'delete',
      'import',
      'export'
    ]
  },
  {
    'id': 'muck',
    'name': '渣土',
    'site': 'admin',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 97},{"action":"add","defaultCheck":false,"description":"新增","pid": 95},{"action":"delete","defaultCheck":false,"description":"删除","pid": 96}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'add',
      'delete'
    ]
  },
  {
    'id': 'tools',
    'site': 'admin',
    'name': '工具库',
    'description': 'tools',
    'status': 1,
    'actionData': '[{"action":"add","defaultCheck":false,"description":"创建","pid": 10000}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add'
    ]
  },
  {
    'id': 'approve_process',
    'site': 'admin',
    'name': '审批流程',
    'description': 'user',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"获取审批流程单","pid": 501}, {"action":"add","defaultCheck":false,"description":"创建修改审批流程单","pid": 502}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'add'
    ]
  },
  {
    'id': 'data',
    'site': 'admin',
    'name': '资料',
    'description': 'data',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查看","pid": 10001}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'statistics',
    'site': 'admin',
    'name': '统计',
    'description': 'property',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"物业资产","pid": 192}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'warehouse',
    'name': '仓库',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 300},{"action":"add","defaultCheck":false,"description":"新增","pid": 300},{"action":"edit","defaultCheck":false,"description":"修改","pid": 300},{"action":"delete","defaultCheck":false,"description":"删除","pid": 300}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'edit',
      'add',
      'delete'
    ]
  },
  {
    'id': 'cargo_space',
    'name': '仓库库位',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 301},{"action":"add","defaultCheck":false,"description":"新增","pid": 301},{"action":"update","defaultCheck":false,"description":"修改","pid": 301},{"action":"delete","defaultCheck":false,"description":"删除","pid": 301}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'update',
      'add',
      'delete'
    ]
  },
  {
    'id': 'warehouse_goods',
    'name': '货品、货品分类',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 302},{"action":"add","defaultCheck":false,"description":"新增","pid": 302},{"action":"update","defaultCheck":false,"description":"修改","pid": 302},{"action":"delete","defaultCheck":false,"description":"删除","pid": 302}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'update',
      'add',
      'delete'
    ]
  },
  {
    'id': 'goods_declaration',
    'name': '商品申报信息',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 303},{"action":"add","defaultCheck":false,"description":"新增","pid": 303},{"action":"update","defaultCheck":false,"description":"修改","pid": 303},{"action":"delete","defaultCheck":false,"description":"删除","pid": 303},{"action":"get","defaultCheck":false,"description":"消费税","pid": 303}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'update',
      'add',
      'delete',
      'get'
    ]
  },
  {
    'id': 'goods_purchase',
    'name': '货品采购',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"货品采购模块","pid": 304}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'goods_purchase_declaration',
    'name': '货品采购申报',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"货品采购申报模块","pid": 305}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'goods_inventory',
    'name': '货品库存',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"货品库存模块","pid": 306}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'transfer_inventory',
    'name': '调拨单',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"调拨单模块","pid": 307}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'goods_loss',
    'name': '商品灭失',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"商品灭失模块","pid": 308}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'wms_inventory',
    'name': '盘存',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"盘存模块","pid": 309}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'wms_order',
    'name': '微信、柜台订单',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"仓库/门店 微信订单和柜台订单","pid": 310}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'wms_picks',
    'name': '拣货单',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"拣货单模块","pid": 311}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'shop_coupon',
    'name': '优惠券',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"优惠券模块","pid": 400}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'shop_discount',
    'name': '折扣、优惠',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"商品折扣和组合优惠模块","pid": 401}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'shop_value_card',
    'name': '储值卡',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"储值卡模块","pid": 402}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'shop_goods_category',
    'name': '商品分类',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"商品分类模块","pid": 403}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'shop_goods',
    'name': '商品',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"商品模块","pid": 404}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'shop_goods_group',
    'name': '商品组',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"商品组模块","pid": 405}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'shop_user',
    'name': '商城用户',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"商城用户模块","pid": 406}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'shop_banner',
    'name': '商城首页滚动图',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"商城首页滚动图模块","pid": 407}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'shop_notify',
    'name': '商城公告',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"商城公告模块","pid": 408}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'shop_delivery_address',
    'name': '商城自提点',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"商城自提点模块","pid": 409}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'shop_wechat_order',
    'name': '商城微信订单',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"商城微信订单模块","pid": 410}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'shop_pay_method',
    'name': '支付方式',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"收银台支付方式模块","pid": 411}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'shop_counter_order',
    'name': '收银台订单',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"收银台订单模块","pid": 412}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'shop_statistics',
    'name': '零售端统计',
    'site': 'admin',
    'description': 'warehouse_shop',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"零售端统计模块","pid": 413}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'bonded_warehouse',
    'name': '保税仓库',
    'site': 'corporation',
    'description': 'bonded',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 105},{"action":"add","defaultCheck":false,"description":"新增","pid": 102},{"action":"delete","defaultCheck":false,"description":"删除","pid": 103},{"action":"update","defaultCheck":false,"description":"修改","pid": 104}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'update',
      'delete'
    ]
  },
  {
    'id': 'bonded_goods',
    'site': 'corporation',
    'name': '保税货品',
    'description': 'bonded',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 109},{"action":"add","defaultCheck":false,"description":"新增","pid": 106},{"action":"delete","defaultCheck":false,"description":"删除","pid": 107},{"action":"edit","defaultCheck":false,"description":"修改","pid": 108}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'bonded_commodity',
    'site': 'corporation',
    'name': '保税商品',
    'description': 'bonded',
    'status': 1,
    'actionData': '[{"action":"add","defaultCheck":false,"description":"新增","pid": 110},{"action":"delete","defaultCheck":false,"description":"删除","pid": 111},{"action":"edit","defaultCheck":false,"description":"修改","pid": 112},{"action":"query","defaultCheck":false,"description":"查询","pid": 113}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'bonded_purchase',
    'site': 'corporation',
    'name': '保税采购',
    'description': 'bonded',
    'status': 1,
    'actionData': '[{"action":"add","defaultCheck":false,"description":"新增","pid": 114},{"action":"delete","defaultCheck":false,"description":"删除","pid": 115},{"action":"edit","defaultCheck":false,"description":"修改","pid": 116},{"action":"query","defaultCheck":false,"description":"查询","pid": 117}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'bonded_out_purchase',
    'site': 'corporation',
    'name': '保税商品出库',
    'description': 'bonded',
    'status': 1,
    'actionData': '[{"action":"add","defaultCheck":false,"description":"新增","pid": 168},{"action":"query","defaultCheck":false,"description":"查询","pid": 167}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'add'
    ]
  },
  {
    'id': 'bonded_stocks',
    'name': '保税库存',
    'site': 'corporation',
    'description': 'bonded',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 118}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'bonded_transfer',
    'name': '保税调拨',
    'site': 'corporation',
    'description': 'bonded',
    'status': 1,
    'actionData': '[{"action":"add","defaultCheck":false,"description":"新增","pid": 119},{"action":"delete","defaultCheck":false,"description":"删除","pid": 120},{"action":"edit","defaultCheck":false,"description":"修改","pid": 121},{"action":"query","defaultCheck":false,"description":"查询","pid": 122}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'bonded_rate',
    'site': 'corporation',
    'name': '汇率',
    'description': 'show',
    'status': 1,
    'actionData': '[{"action":"add","defaultCheck":false,"description":"新增","pid": 123},{"action":"delete","defaultCheck":false,"description":"删除","pid": 124},{"action":"query","defaultCheck":false,"description":"查询","pid": 125}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'exhibition_warehouse',
    'site': 'corporation',
    'name': '展示仓库',
    'description': 'show',
    'status': 1,
    'actionData': '[{"action":"add","defaultCheck":false,"description":"新增","pid": 126},{"action":"delete","defaultCheck":false,"description":"删除","pid": 127},{"action":"edit","defaultCheck":false,"description":"修改","pid": 128},{"action":"query","defaultCheck":false,"description":"查询","pid": 129}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'exhibition_goods',
    'site': 'corporation',
    'name': '展示货品',
    'description': 'show',
    'status': 1,
    'actionData': '[{"action":"add","defaultCheck":false,"description":"新增","pid": 130},{"action":"delete","defaultCheck":false,"description":"删除","pid": 131},{"action":"edit","defaultCheck":false,"description":"修改","pid": 132},{"action":"query","defaultCheck":false,"description":"查询","pid": 133}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'exhibition_commodity',
    'site': 'corporation',
    'name': '展示商品',
    'description': 'show',
    'status': 1,
    'actionData': '[{"action":"add","defaultCheck":false,"description":"新增","pid": 134},{"action":"delete","defaultCheck":false,"description":"删除","pid": 135},{"action":"edit","defaultCheck":false,"description":"修改","pid": 136},{"action":"query","defaultCheck":false,"description":"查询","pid": 137}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'exhibition_warehouse_transfer',
    'name': '展示商品进出区',
    'site': 'corporation',
    'description': 'show',
    'status': 1,
    'actionData': '[{"action":"add","defaultCheck":false,"description":"新增","pid": 138},{"action":"delete","defaultCheck":false,"description":"删除","pid": 139},{"action":"edit","defaultCheck":false,"description":"修改","pid": 140},{"action":"query","defaultCheck":false,"description":"查询","pid": 141}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'qid',
    'name': '创建qid',
    'site': 'corporation',
    'description': 'show',
    'status': 1,
    'actionData': '[{"action":"add","defaultCheck":false,"description":"新增","pid": 142},{"action":"delete","defaultCheck":false,"description":"删除","pid": 143},{"action":"edit","defaultCheck":false,"description":"修改","pid": 144},{"action":"query","defaultCheck":false,"description":"查询","pid": 145}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'normal_commodity',
    'name': '一般商品',
    'site': 'corporation',
    'description': 'show',
    'status': 1,
    'actionData': '[{"action":"add","defaultCheck":false,"description":"新增","pid": 146},{"action":"delete","defaultCheck":false,"description":"删除","pid": 147},{"action":"edit","defaultCheck":false,"description":"修改","pid": 148},{"action":"query","defaultCheck":false,"description":"查询","pid": 149},{"action":"import","defaultCheck":false,"description":"导入","pid": 169}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'edit',
      'delete',
      'import'
    ]
  },
  {
    'id': 'normal_commodity_stock',
    'name': '一般商品库存',
    'site': 'corporation',
    'description': 'show',
    'status': 1,
    'actionData': '[{"action":"add","defaultCheck":false,"description":"新增","pid": 150},{"action":"query","defaultCheck":false,"description":"查询","pid": 151}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'exhibition_order',
    'site': 'corporation',
    'name': '订单',
    'description': 'show',
    'status': 1,
    'actionData': '[{"action":"add","defaultCheck":false,"description":"新增","pid": 152},{"action":"edit","defaultCheck":false,"description":"修改","pid": 153},{"action":"query","defaultCheck":false,"description":"查询","pid": 154}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'refund',
    'site': 'corporation',
    'name': '退款',
    'description': 'show',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"退款信息","pid": 155}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'inspur_QID',
    'site': 'corporation',
    'name': '浪潮QID',
    'description': 'show',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 162},{"action":"get","defaultCheck":false,"description":"记录查询","pid": 161}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'get'
    ]
  },
  {
    'id': 'take_inventory',
    'site': 'corporation',
    'name': '盘存记录',
    'description': 'show',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 163},{"action":"edit","defaultCheck":false,"description":"编辑","pid": 164}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'edit'
    ]
  },
  {
    'id': 'declaration',
    'site': 'corporation',
    'name': '申报单',
    'description': 'show',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 165},{"action":"detail","defaultCheck":false,"description":"详情","pid": 166}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'detail'
    ]
  },
  {
    'id': 'customs_entry_1210',
    'site': 'corporation',
    'name': '1210报关',
    'description': 'customs_declaration',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 203},{"action":"edit","defaultCheck":false,"description":"编辑","pid": 202},{"action":"delete","defaultCheck":false,"description":"删除","pid": 201},{"action":"add","defaultCheck":false,"description":"创建","pid": 200}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'edit',
      'delete',
      'add'
    ]
  },
  {
    'id': 'export_order',
    'site': 'corporation',
    'name': '出口单据',
    'description': 'customs_declaration',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 227},{"action":"add","defaultCheck":false,"description":"创建","pid": 224},{"action":"edit","defaultCheck":false,"description":"修改","pid": 226},{"action":"delete","defaultCheck":false,"description":"删除","pid": 225}]',
    'sptDaTypes': null,
    'optionalFields': null,
    'parents': null,
    'type': null,
    'deleted': 0,
    'actions': [
      'add',
      'edit',
      'query',
      'delete'
    ]
  },
  {
    'id': 'customs_config_1210',
    'site': 'corporation',
    'name': '1210配置',
    'description': 'customs_declaration',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 206},{"action":"edit","defaultCheck":false,"description":"编辑","pid": 205}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'edit'
    ]
  },
  {
    'id': 'wx_applet_config',
    'site': 'corporation',
    'name': '微信小程序配置',
    'description': 'config',
    'status': 1,
    'actionData': '[{"action":"add","defaultCheck":false,"description":"微信小程序企业配置","pid": 228}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'add',
      'query',
      'get'
    ]
  },
  {
    'id': 'logistics_config',
    'site': 'corporation',
    'name': '快递配置',
    'description': 'config',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"查询","pid": 359},{"action":"add","defaultCheck":false,"description":"创建","pid": 359},{"action":"edit","defaultCheck":false,"description":"编辑","pid": 359},{"action":"delete","defaultCheck":false,"description":"删除","pid": 359}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'add',
      'edit',
      'delete'
    ]
  },
  {
    'id': 'trade_base',
    'name': '贸易基础项',
    'site': 'admin',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"增删改查操作","pid": 601}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'trade_project',
    'name': '贸易项目',
    'site': 'admin',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"项目以及项目相关模块增删改查操作","pid": 602}, {"action":"add","defaultCheck":false,"description":"项目关联存档","pid": 604}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'add'
    ]
  },
  {
    'id': 'trade_approval',
    'name': '贸易审批',
    'site': 'admin',
    'description': null,
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"审批流程模板获取、我的审批、全部审批","pid": 603}, {"action":"add","defaultCheck":false,"description":"审批模板的创建修改","pid": 605}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query',
      'add'
    ]
  },
  {
    'id': 'oa_user',
    'name': 'OA用户',
    'site': 'admin',
    'description': 'oa',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"用户相关操作","pid": 701}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'oa_contract',
    'name': 'OA合同',
    'site': 'admin',
    'description': 'oa',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"合同相关操作","pid": 702}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'oa_department',
    'name': 'OA部门',
    'site': 'admin',
    'description': 'oa',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"部门相关操作","pid": 703}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'oa_role',
    'name': 'OA角色',
    'site': 'admin',
    'description': 'oa',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"角色相关操作","pid": 704}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  },
  {
    'id': 'oa_role_category',
    'name': 'OA角色分类',
    'site': 'admin',
    'description': 'oa',
    'status': 1,
    'actionData': '[{"action":"query","defaultCheck":false,"description":"角色分类相关操作","pid": 705}]',
    'sptDaTypes': null,
    'optionalFields': '[]',
    'parents': null,
    'type': 'default',
    'deleted': 0,
    'actions': [
      'query'
    ]
  }
]

export { role, permissionNoPager }
